document.addEventListener('DOMContentLoaded', (e) => {

    // scroll-triggered svg icon blocks
    let iconsFn = (entries) => entries.forEach(entry => {
        if (entry.isIntersecting) {
            $(entry.target).addClass('animate');
            $(entry.target).find('svg').addClass('active');
        } 
        // else{
        //     $(entry.target).find('svg').removeClass('active');
        // }
    });
    const config = { rootMargin: '0px 0px',	threshold: .5 };
    let observer = new IntersectionObserver(iconsFn, config);
    document.querySelectorAll('.block--icon-with-text').forEach(el => observer.observe(el));

});